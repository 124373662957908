var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "table",
          expression: "widget.compType === 'table'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_display_serial_number") } },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.widget.properties.showIndex,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "showIndex", $$v)
                  },
                  expression: "widget.properties.showIndex",
                },
              },
              [
                _c("el-radio", { attrs: { label: true } }, [
                  _vm._v(" " + _vm._s(_vm.$t("lang_display")) + " "),
                ]),
                _c("el-radio", { attrs: { label: false } }, [
                  _vm._v(" " + _vm._s(_vm.$t("lang_hide")) + " "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_summarize") } },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.widget.properties.summary,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "summary", $$v)
                  },
                  expression: "widget.properties.summary",
                },
              },
              [
                _c("el-radio", { attrs: { label: true } }, [
                  _vm._v(" " + _vm._s(_vm.$t("lang_yes")) + " "),
                ]),
                _c("el-radio", { attrs: { label: false } }, [
                  _vm._v(" " + _vm._s(_vm.$t("lang_no")) + " "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_summary_column") } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  disabled: !_vm.widget.properties.summary,
                  multiple: "",
                  placeholder: "",
                },
                model: {
                  value: _vm.widget.properties.summaryColumnKeys,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "summaryColumnKeys", $$v)
                  },
                  expression: "widget.properties.summaryColumnKeys",
                },
              },
              _vm._l(_vm.widget.properties.components, function (column) {
                return _c("el-option", {
                  key: column.compCode,
                  attrs: {
                    label: _vm.$t(column.compName),
                    value: column.compCode,
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_table_min_row") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: { type: "number", clearable: "" },
              model: {
                value: _vm.widget.properties.minRowNum,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "minRowNum", _vm._n($$v))
                },
                expression: "widget.properties.minRowNum",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_table_max_row") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: { type: "number", clearable: "" },
              model: {
                value: _vm.widget.properties.maxRowNum,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "maxRowNum", _vm._n($$v))
                },
                expression: "widget.properties.maxRowNum",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_operation_status") } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: _vm.$t("lang_please_select"),
                  clearable: "",
                },
                model: {
                  value: _vm.widget.properties.operation,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "operation", $$v)
                  },
                  expression: "widget.properties.operation",
                },
              },
              _vm._l(_vm.statusList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.label, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }